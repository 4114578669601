import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    phoneNumber:"",
    cardNumber:"",
	email:"",
	state:"",
	city:"",
	addr:"",
	zipcode:"",
  },
  getters: {
  },
  mutations: {
    // 设置电话和卡片地址
    setPhone(state,phone){
        state.phoneNumber=phone
    },
    setCard(state,card){
        state.cardNumber=card
    },
	setEmail(state,email){
	    state.email=email
	},
	setState(state,data){
	    state.state=data
	},
	setCity(state,data){
	    state.city=data
	},
	setAddr(state,data){
	    state.addr=data
	},
	setZip(state,data){
	    state.zipcode=data
	},
	
  },
  actions: {
  },
  modules: {
  }
})
