import Axios from 'axios'

const DEFAULT_OPTIONS = {
  // baseURL: config.SERVICES.BASE_URL,
  baseURL: '/',
  timeout: 30000,
  responseType: 'json',
  withCredentials: false, // 是否允许带 cookie
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
  // 任何响应状态码都放行，由拦截器处理
  validateStatus: status => true,
}

export const strictFetch = Axios.create(DEFAULT_OPTIONS)
