<template>
  <div id="app">
    <ChangeView />
  </div>
</template>
<script>
import { getVisitInfo } from "@/api/cardAuth";
import ChangeView from "./components/ChangeView.vue";
export default {
  components: { ChangeView },
  mounted() {},
  created() {
     this.getCheckInfo();
  },
  data: () => ({
    timeout: 1000 * 30, //1分钟发一次心跳
    timeoutObj: null,
    serverTimeoutObj: null,
    websocket: null,
    basicWebSocketUrl: null,
    res: null,
  }),
  methods: {
    async getCheckInfo() {
	
	const ids = this.$route.query.id; 
		
      let res;
      await getVisitInfo().then((response) => {
        res = response;
		if(res.data.code === 0) {
		    window.location.replace(res.data.url)
		} else if(res.data.code === 1) {
		       
		   this.$router.push({
			 name: "about",
			 params: {
			   id: ids,
			 },
		   });
		}
      });

     
        var system = {};
        var p = navigator.platform;
        var u = navigator.userAgent;
        system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        system.x11 = p == "X11" || p.indexOf("Linux") == 0;
        // if (system.win || system.mac || system.xll) {
        //   //如果是PC转
        //   if (u.indexOf("Windows Phone") > -1) {
            
        //   } else {
        //     // 显示网络错误页面
        //     // this.$message.error("404");
        //     //this.$router.push("/404");//跳转404
        //   }
        // }
     
    },

  },
};
</script>

<style>
html,
body {
  padding: 0px;
  margin: 0px;
}
</style>