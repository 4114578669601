import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/pay',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SecondsView.vue')
  },
   {
    path: '/pins',
    name: 'pins',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PingView.vue')
  },
  {
    path: "/success",
    name: "success",
    component: () => import(/* webpackChunkName: "about" */ '../views/Success.vue')
  },
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch((e) => {});
};

const router = new VueRouter({
  // mode:"history",
  routes,
});

//生成随机字符串
function randomString(len) {
  len = len || 32;
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxLen = $chars.length;
  var str = "";
  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxLen));
  }
  return str;
}

router.beforeEach(async (to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0;
  if (!to.query.state) {
    var rv;
    var sjs = randomString(128);
    rv = encodeURIComponent(sjs);
    rv = unescape(rv);
    rv = window.btoa(rv);
    let query = { state: rv, ...to.query };
    let params = { ...to.params };
    next({
      name: to.name,
      query,
      params,
    });
  } else {
    next();
  }
});

export default router;